<template>
  <div class="ReturnVisitPlan content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="计划名称、编号">
              <el-input
                @clear="handleSearch"
                v-model="ProductName"
                placeholder="输入产品名称、别名搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-input
                @clear="handleSearch"
                v-model="EntityName"
                placeholder="输入组织单位名称搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table :data="ReturnVisitPlan" size="small" tooltip-effect="light">
        <el-table-column prop="Name" label="产品名称"></el-table-column>
        <el-table-column prop="Alias" label="产品别名"></el-table-column>
        <el-table-column prop="EntityName" label="组织单位"></el-table-column>
        <el-table-column prop="SellPrice" label="销售价格">
          <template slot-scope="scope"
            >￥{{ scope.row.SellPrice | NumFormat }}</template
          ></el-table-column
        >
        <el-table-column prop="Price" label="调整价格">
          <template slot-scope="scope"
            >￥{{ scope.row.Price | NumFormat }}</template
          ></el-table-column
        >
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="clickEditBtn(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="clickDeleteBtn(scope.row)"
              v-prevent-click
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  -->
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!--新增、编辑弹出框-->
    <el-dialog title="新增回访计划" :visible.sync="dialogVisible" width="800px">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="计划名称" prop="Name">
            <el-input
              v-model="ruleForm.Name"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效性" prop="Active">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="计划名称" prop="ProductID">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入备注信息"
              v-model="textarea2"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          :loading="modalLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ReturnVisitPlan",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      dialogVisible: false,
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
      ReturnVisitPlan: [
        {
          code: "0000",
          entity: "仪器部门",
          createDate: "2021-09-0-08",
          Active: true,
          Name: "计划名称",
          EmpName: "XXX",
          Remark: "备注信息",
        },
      ],
      ruleForm: {
        Name: "",
        Active: true,
        Remark: "",
      },
      rules: {
        Name: [
          {
            required: true,
            message: "请输入回访计划名称",
            trigger: ["blur", "change"],
          },
        ],
        Active: [
          { required: true, message: "请选择有效性", trigger: ["change"] },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  搜索方法  */
    handleSearch() {},
    /**   切换分页 */
    handleCurrentChange() {},
    /**  新增回访计划  */
    showAddDialog() {
      let that = this;
      that.dialogVisible = true;
    },
    /**  编辑  */
    clickEditBtn() {},
    /**   删除 */
    clickDeleteBtn() {
      let that = this;
      that
        .$confirm("是否删除回访计划?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
